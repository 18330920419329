import { Injectable, inject } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

/** Copypaste from https://itnext.io/manage-angular-page-titles-translation-d1384bbede86 */
@Injectable()
export class CustomPageTitleStrategy extends TitleStrategy {
  private _title = inject(Title);
  private _translateService = inject(TranslateService);

  constructor() {
    super();
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);

    if (!title) {
      this._title.setTitle('PIN-UP.Partners - Your favorite iGaming affiliate program!');

      return;
    }

    this._translateService.get(title).subscribe((translatedTitle: string) => {
      this._title.setTitle(translatedTitle);
    });
  }
}
