import { HttpClient } from '@angular/common/http';
import {
  EnvironmentProviders,
  importProvidersFrom,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment, Languages, languagesLocaleRecord } from '@shared/models';

export function localeFactory(): string {
  const translateService = inject(TranslateService);

  return languagesLocaleRecord[translateService.currentLang as Languages];
}

function HttpLoaderFactory(): TranslateHttpLoader {
  const http = inject(HttpClient);

  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const provideTranslate = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    importProvidersFrom([
      TranslateModule.forRoot({
        defaultLanguage: environment.defaultLanguage,
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
        },
      }),
    ]),
  ]);
