import { Route } from '@angular/router';

import { AffiliateAgreementComponent, PrivacyPolicyComponent } from '@static-pages';

import { HomeComponent, ShellComponent } from './core/components';

export const routes: Route[] = [
  {
    path: '404',
    loadComponent: () => import('./core/components').then(m => m.NotFoundComponent),
  },
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: '',
        title: 'PinUp World',
        component: HomeComponent,
      },
      {
        path: 'affiliate-agreement',
        title: 'menu.affiliateAgreement',
        // TODO: Change to lazy loading
        component: AffiliateAgreementComponent,
      },
      {
        path: 'privacy-policy',
        title: 'menu.affiliateAgreement',
        // TODO: Change to lazy loading
        component: PrivacyPolicyComponent,
      },
    ],
  },
  {
    path: '**',
    loadComponent: () => import('./core/components').then(m => m.NotFoundComponent),
  },
];
