import { isPlatformBrowser } from '@angular/common';
import {
  APP_INITIALIZER,
  EnvironmentProviders,
  inject,
  makeEnvironmentProviders,
  PLATFORM_ID,
} from '@angular/core';
import { TitleStrategy } from '@angular/router';

import { AUTH_PROVIDER_TOKEN, AuthService, HttpAuthProvider } from '@auth/core';
import { GEO_PROVIDER_TOKEN, HttpGeoProvider } from '@shared/providers';
import { catchError, Observable, of } from 'rxjs';
import { GeoService } from '@shared/services';
import { GeoResponse } from '@shared/models';

import { CustomPageTitleStrategy } from '../utils/custom-page-title-strategy';

export function redirectFactory(): () => Observable<boolean> {
  const authService = inject(AuthService);
  const platform = inject(PLATFORM_ID);
  const isBrowser = isPlatformBrowser(platform);
  const isTokenValid$ = authService.isTokenValid().pipe(
    catchError(() => {
      // TODO: Uncomment this line when the JWT token is implemented
      // redirectToCabinet();

      return of(null);
    }),
  );

  return () => (isBrowser ? isTokenValid$ : of(true));
}

export function geoFactory(): () => Observable<GeoResponse> {
  const geoService = inject(GeoService);

  return () => geoService.getGeo();
}

export const provideCore = (): EnvironmentProviders =>
  makeEnvironmentProviders([
    { provide: AUTH_PROVIDER_TOKEN, useClass: HttpAuthProvider },
    { provide: GEO_PROVIDER_TOKEN, useClass: HttpGeoProvider },
    { provide: TitleStrategy, useClass: CustomPageTitleStrategy },
    { provide: APP_INITIALIZER, useFactory: redirectFactory, multi: true },
    { provide: APP_INITIALIZER, useFactory: geoFactory, multi: true },
  ]);
